<template>
  <AppPageCard>
    <template #card-title> Employee Individual Yearly Attendance </template>
    <div>
      <span
        class="
          text-muted
          font-weight-bold font-size-base
          d-none d-md-inline
          mr-1
        "
      >
        {{ $t("EMPLOYEE.EMP") }}
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        {{ getTitle() }}
      </span>
      <span
        class="
          text-muted
          font-weight-bold font-size-base
          d-none d-md-inline
          mr-1
        "
      >
        {{ "Attendance Year" }}
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        {{ getYear() }}
      </span>
      <IndividualEmployeeAttendanceYearlyPageTable
        :is-busy="isBusy"
        :items="items"
        @showIndividualAttendance="openModal"
      ></IndividualEmployeeAttendanceYearlyPageTable>
    </div>
    <EmployeeAttendanceMonthlyModal></EmployeeAttendanceMonthlyModal>
  </AppPageCard>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import IndividualEmployeeAttendanceYearlyPageTable from "@/modules/company/components/attendance/yearly/individual/IndividualEmployeeAttendanceYearlyPageTable";
import EmployeeAttendanceMonthlyModal from "@/modules/company/components/attendance/monthly/EmployeeAttendanceMonthlyModal";

export default {
  name: "IndividualEmployeeAttendanceYearlyPage",
  components: {
    EmployeeAttendanceMonthlyModal,
    IndividualEmployeeAttendanceYearlyPageTable,
    AppPageCard,
  },
  data() {
    return {
      isBusy: false,
      date: new Date(dayjs().format()),
    };
  },
  mounted() {
    this.setDate();
    this.fetch();
  },
  methods: {
    getTitle() {
      return this.$route.query.name;
    },

    getYear() {
      return this.$route.query.year;
    },

    setDate() {
      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const date = dayjs({
        year: this.getYear(),
      });

      this.$store.dispatch("changeEmployeeYearlyAttendanceListDate", {
        date: date.format(),
      });
    },

    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchIndividualEmployeeYearlyAttendanceList", {
          id: this.$route.query.id,
        })
        .finally(() => (this.isBusy = false));
    },

    openModal(data) {
      console.log("Data when open modal: ", data);
      this.$store.dispatch(
        "assignIndividualEmployeeAttendance",
        data.item.employee
      );

      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const date = dayjs({
        year: this.getYear(),
        month: data.item.month_number - 1,
      });

      console.log("date before taken: ", date);

      const formatted = dayjs(date).toDate();
      console.log("date taken:", formatted);

      this.$store.dispatch("assignIndividualEmployeeAttendanceDate", formatted);

      this.$bvModal.show("employee-page-clock-record-modal");
    },
  },
  computed: {
    ...mapGetters({
      items: "getIndividualEmployeeYearlyAttendanceList",
    }),
  },
};
</script>

<style scoped></style>
