<template>
  <div>
    <b-table
      :busy="isBusy"
      :fields="fields"
      :items="getIndividualEmployeeAttendanceList"
      table-class="table-head-custom table-vertical-center table-head-bg mt-3"
      responsive
      hover
      show-empty
      outlined
      small
    >
      <!-- begin:header template    -->
      <template v-slot:head(date)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>
      <!-- end:header template    -->

      <!-- begin: cell templates      -->
      <template v-slot:cell(date)="data">
        <div class="d-flex align-items-center">
          <div class="ml-4">
            <AppSmallLightButton>
              {{ transformDate(data.item.date) }}
            </AppSmallLightButton>
          </div>
        </div>
      </template>
      <!-- end:: date      -->

      <!-- begin:: date      -->
      <template v-slot:cell(day)="data">
        <div class="d-flex align-items-center">
          <div class="ml-4">
            <span class="ml-3 font-weight-bold">{{
              getDay(data.item.date)
            }}</span>
          </div>
        </div>
      </template>
      <!-- end:: date      -->

      <template v-slot:cell(time_in)="data">
        <TheTimeEntryArrow
          v-if="data.item.attendance"
          :time="data.item.attendance.time_in"
          :is-late="data.item.attendance.late"
          :display-late="true"
          type="in"
        ></TheTimeEntryArrow>
      </template>

      <template v-slot:cell(time_out)="data">
        <TheTimeEntryArrow
          v-if="data.item.attendance"
          :time="data.item.attendance.time_out"
          type="out"
          :display-late="false"
        ></TheTimeEntryArrow>
      </template>

      <template v-slot:cell(status)="data">
        <span>{{ data.item.status.name }}</span>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-right">
          <!-- begin: Device Users Modal Button         -->
          <button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showEmployeeAttendance(data)"
            v-b-tooltip.hover="$t('ATTENDANCE.HOVER.CLOCK')"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Home/Alarm-clock.svg" />
            </span>
          </button>
          <b-button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showManageModal(data)"
            v-b-tooltip.hover="$t('ATTENDANCE.HOVER.MODIFY')"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Group.svg" />
            </span>
          </b-button>
          <b-button
            class="btn btn-icon btn-light btn-hover-primary mr-3"
            v-on:click="showUpdateModal(data)"
            v-b-tooltip.hover="$t('ATTENDANCE.HOVER.TIME')"
          >
            <span class="svg-icon svg-icon-md svg-icon-secondary menu-icon">
              <inline-svg src="/media/svg/icons/Home/Clock.svg" />
            </span>
          </b-button>
        </div>
      </template>
      <!-- end: cell templates      -->

      <template #table-busy>
        <AppLoader></AppLoader>
      </template>
    </b-table>

    <div
      class="justify-content-end"
      v-if="getIndividualEmployeeAttendanceList.length"
    >
      <b-pagination
        align="end"
        class="my-0"

        @change="changePage"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
      ></b-pagination>
    </div>

    <EmployeePageClockRecordModal
      :attendance-id="attendanceId"
    ></EmployeePageClockRecordModal>

    <EmployeeAttendanceManageModal></EmployeeAttendanceManageModal>

    <EmployeeAttendanceUpdateTimeModal
      :employee="employee"
      :attendance="selectedAtt"
      @formSubmitted="fetch"
    ></EmployeeAttendanceUpdateTimeModal>
  </div>
</template>

<script>
import i18n from "@/core/plugins/vue-i18n";
import { mapGetters } from "vuex";
import EmployeePageClockRecordModal from "@/modules/company/components/employees/EmployeePageClockRecordModal";
import EmployeeAttendanceManageModal from "@/modules/company/components/attendance/manage/EmployeeAttendanceManageModal";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import dayjs from "dayjs";
import TheTimeEntryArrow from "@/modules/company/components/shared/TheTimeEntryArrow";
import AppSmallLightButton from "@/modules/core/components/button/AppSmallLightButton";
import EmployeeAttendanceUpdateTimeModal from "@/modules/company/components/attendance/manage/time/EmployeeAttendanceUpdateTimeModal";

export default {
  name: "IndividualEmployeeAttendanceMonthlyTable",
  components: {
    EmployeeAttendanceUpdateTimeModal,
    AppSmallLightButton,
    TheTimeEntryArrow,
    AppLoader,
    EmployeeAttendanceManageModal,
    EmployeePageClockRecordModal,
  },
  data() {
    return {
      fields: [
        { key: "date", label: "Date" },
        { key: "day", label: "Day" },
        { key: "time_in", label: i18n.t("ATTENDANCE.IN") },
        { key: "time_out", label: i18n.t("ATTENDANCE.OUT") },
        { key: "temperature", label: i18n.t("ATTENDANCE.TEMPERATURE") },
        { key: "status", label: "Status" },
        { key: "actions", label: i18n.t("ATTENDANCE.ACTIONS") },
      ],
      attendanceId: null,
      isBusy: true,
      selectedAtt: {},
    };
  },
  mounted() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchIndividualEmployeeAttendance")
        .finally(() => (this.isBusy = false));
    },

    /**
     *
     * @param page
     */
    changePage(page) {
      this.$store.commit("setAssignedIndividualEmployeeAttendancePage", page);

      this.fetch();
    },

    showEmployeeAttendance(data) {
      this.attendanceId = data.item.attendance ? data.item.attendance.id : null;
      this.$bvModal.show("employee-page-clock-record-modal");
    },

    showManageModal(data) {
      console.log(data);
      const row = data.item;
      const employee = this.employee;
      console.log(employee);
      const emp = {
        id: employee.id,
        name: employee.name,
        code: employee.code,
        email: employee.email,
        att_status: {
          id: row.status.id,
        },
      };
      this.$store.dispatch("assignDailyEmployeeAttendance", {
        data: emp,
        date: dayjs(new Date(data.item.date)).format(),
      });
      this.$bvModal.show("emp-attendance-manage-modal");
    },

    showUpdateModal(data) {
      if (data.item.attendance === null) {
        this.$bvModal.msgBoxOk("Employee doesnt have attendance", {
          title: "Attendance is empty",
          centered: true,
        });
        return null;
      }

      this.selectedAtt = null;

      this.selectedAtt = {
        id: data.item.attendance.id,
        date: data.item.date,
        in_fmt: data.item.attendance.time_in_fmt,
        out_fmt: data.item.attendance.time_out_fmt,
      };

      this.$bvModal.show("common-attendance-update-time-modal");
    },

    transformDate(date) {
      const t = new Date(date);
      // https://day.js.org/docs/en/display/format
      return dayjs(t).format("DD/MM");
    },

    getDay(date) {
      const t = new Date(date);
      // https://day.js.org/docs/en/display/format
      return dayjs(t).format("dddd");
    },

    refresh() {},
  },

  computed: {
    ...mapGetters(["getIndividualEmployeeAttendanceList"]),
    ...mapGetters({
      employee: "getAssignedIndividualEmployeeAttendance",
      pagination: "getAssignedIndividualEmployeeAttendancePagination",
    }),
  },
};
</script>

<style scoped></style>
