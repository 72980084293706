<template>
  <div>
    <span class="symbol symbol-35 symbol-light mr-2">
      <span class="symbol-label svg-icon svg-icon-light-primary">
        <inline-svg :src="getIcon()" />
      </span>
    </span>

    <span class="ml-3" v-if="displayLate">
      <span
        class="menu-item bg-light-danger rounded px-2 py-2 text-danger"
        v-if="isLate"
        v-b-tooltip.hover="'Late'"
      >
        {{ time }}
      </span>
      <span class="" v-if="!isLate">
        {{ time }}
      </span>
    </span>

    <span class="ml-3" v-else>
      {{ time }}
    </span>
  </div>
</template>

<script>
export default {
  name: "TheTimeEntryArrow",
  props: {
    attendance: Object,
    type: String,
    time: String,
    isLate: Boolean,
    displayLate: Boolean,
  },
  methods: {
    getIcon() {
      switch (this.type) {
        case "in":
          return "/media/svg/icons/Navigation/Arrow-up.svg";
        case "out":
          return "/media/svg/icons/Navigation/Arrow-down.svg";
        default:
          break;
      }
    },
  },
};
</script>

<style scoped></style>
