<template>
  <b-modal
    id="employee-page-clock-record-modal"
    title="Employee Monthly Attendance Review"
    size="xl"
    footer-bg-variant="light"
    ok-only
    lazy
  >
    <div class="d-flex flex-column flex-lg-row p-3 mr-5">
      <CommonEmployeeSummary v-if="item" :item="item"></CommonEmployeeSummary>
    </div>
    <IndividualEmployeeAttendanceMonthlyTable></IndividualEmployeeAttendanceMonthlyTable>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import IndividualEmployeeAttendanceMonthlyTable from "@/modules/company/components/attendance/monthly/IndividualEmployeeAttendanceMonthlyTable";
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";

export default {
  name: "EmployeeAttendanceMonthlyModal",
  components: {
    CommonEmployeeSummary,
    IndividualEmployeeAttendanceMonthlyTable,
  },
  data() {
    return {};
  },
  mounted() {
    console.log("Hello!!");
  },
  methods: {},
  computed: {
    ...mapGetters({
      item: "getAssignedIndividualEmployeeAttendance",
      date: "getAssignedIndividualEmployeeAttendanceDate",
      close: "getDailyEmployeeAttendanceFormState",
    }),
  },
};
</script>

<style scoped></style>
